








































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PolicyStatusAllianzModal from '@/vue-app/components/PolicyStatusAllianzModal.vue';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import SowosWealthInfo from '@/vue-app/components/my-investment-dashboard-v2/SowosWealthInfo.vue';
import SowosWealthCardViewModel
  from '@/vue-app/view-models/components/my-investment-dashboard-v2/sowos-wealth-card-view-model';

@Component({
  name: 'SowosWealthCard',
  components: {
    PolicyStatusAllianzModal,
    ContractSavings: () => import('@/vue-app/components/ContractSavings.vue'),
    LoadingDataCard,
    SowosWealthInfo,
    ProductIsUnavailableToHireDialog: () => import('@/vue-app/components/my-investment-dashboard-v2/ProductIsUnavailableToHireDialog.vue'),
  },
})
export default class SowosWealthCard extends Vue {
  sowos_wealth_card_model= Vue.observable(new SowosWealthCardViewModel());

  @Watch('sowos_wealth_card_model.investor_profile_state_manager.state.investor_profile.id')
  onInvestorProfileIdChange(id: string) {
    if (id) {
      this.sowos_wealth_card_model.getRecommendedProduct(id);
    }
  }

  created() {
    this.sowos_wealth_card_model.initialize();
  }
}
