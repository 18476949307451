import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { currencyFormat } from '@/vue-app/utils/currency';
import { v4 } from 'uuid';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import {
  GetAllianzPolicyIssuanceStatusQuery,
} from '@/modules/my-investment/allianz/allianz-policy-issuance-status/application/queries';
import GetRecommendedInvestmentProductQuery
  from '@/modules/flagship/investor-profile/recommended-investment-product/application/queries/get-recommended-investment-product-query';
import InvestmentSuggestionQuery
  from '@/modules/investment-suggestion/application/queries/investment-suggestion-query';
import InvestmentSuggestionCommand
  from '@/modules/investment-suggestion/application/commands/investment-suggestion-command';
import {
  FindAvailabilityToHireProductQuery,
} from '@/modules/my-investment/investment-product/application/queries';
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';

// Domain
import { InvestorProfileStateManager }
  from '@/modules/flagship/investor-profile/investor-profile/domain/state/investor-profile-state-manager';
import {
  InvestmentSuggestionDto,
} from '@/modules/investment-suggestion/domain/dtos/investment-suggestion-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { Router } from '@/modules/shared/domain/router';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class SowosWealthCardViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.GET_ALLIANZ_POLICY_ISSUANCE_STATUS_QUERY)
  private readonly get_allianz_policy_issuance_status_query!: GetAllianzPolicyIssuanceStatusQuery;

  @Inject(TYPES.GET_RECOMMENDED_INVESTMENT_PRODUCT_QUERY)
  private readonly get_recommended_product_query!: GetRecommendedInvestmentProductQuery;

  @Inject(TYPES.INVESTOR_PROFILE_STATE_MANAGER)
  private readonly investor_profile_state_manager!: InvestorProfileStateManager;

  @Inject(TYPES.GET_INVESTMENT_SUGGESTION_QUERY)
  private readonly get_investment_suggestion_query!: InvestmentSuggestionQuery;

  @Inject(TYPES.CREATE_INVESTMENT_SUGGESTION_COMMAND)
  private readonly create_investment_suggestion_command!: InvestmentSuggestionCommand;

  @Inject(TYPES.FIND_AVAILABILITY_TO_HIRE_PRODUCT_QUERY)
  private readonly find_availability_to_hire_product!: FindAvailabilityToHireProductQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products!: GetInvestmentProductsQuery;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly i18n_namespace = 'components.my-investment-dashboard.sowos-wealth-card';

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  private readonly error_allianz_policy_status_not_exists = `Error allianz_policy_issuance_status_entity for customer_id_value:${this.customer_id}, not exists`;

  private readonly error_allianz_account_report_not_exits = 'allianz_account_report_by_customer_id_list:[], not exists';

  readonly asset = 'icon_ahorro.svg';

  is_loading = false;

  balance = '0.00 MXN';

  updated_date = '';

  has_allianz_account = false;

  bg_image = {
    backgroundImage: '@/assets/images/investment-dashboard/background_blue.svg',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: 'right',
    backgroundPositionY: 'bottom',
  }

  is_policy_on_issue_process = false;

  is_status_account_manual = false;

  is_first_interaction_with_dashboard = false;

  first_contribution_was_applied = false;

  allianz_policy_number = '';

  show_status_allianz_modal = false;

  display_contract_savings = false;

  show_contract_savings_modal = false;

  show_info = false;

  show_recommended = false;

  product_unavailable_to_hire = '';

  private investment_suggestion_dto: InvestmentSuggestionDto = {
    id: v4(),
    investment_suggestion_analytic_id: '',
    action: 'allianz',
  }

  loadAllianzBalance = async () => {
    try {
      const allianz_account_report_list = await this.search_allianz_account_report_query.execute(
        this.customer_id,
      );
      if (allianz_account_report_list.length) {
        const allianz_balance = allianz_account_report_list.reduce(
          (total, current) => total + current.final_balance_mxn!, 0,
        );
        this.balance = `${currencyFormat(allianz_balance)} MXN`;
        const { created_at } = allianz_account_report_list[0];
        this.updated_date = created_at ? this.date_formatter.formatDate(created_at, 'DD MMM YYYY') : this.translate('not_loaded_date');
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_report_not_exits) {
        this.message_notifier.showErrorNotification(
          i18n.tc(`${this.i18n_namespace}.error_message`),
        );
      }
    }
  }

  get step_number_policy_status() {
    // eslint-disable-next-line no-nested-ternary
    if (this.allianz_policy_number && !this.first_contribution_was_applied) {
      return 2;
    }
    if (this.has_allianz_account && this.is_first_interaction_with_dashboard) {
      return 3;
    }
    return 1;
  }

  get policy_on_review_or_pending_first_contribution() {
    return this.is_policy_on_issue_process || this.policy_with_pending_first_contribution;
  }

  get policy_with_pending_first_contribution() {
    return this.allianz_policy_number && !this.first_contribution_was_applied;
  }

  validateIfPolicyIsOnProcess = async () => {
    try {
      const allianz_policy_status = await this.get_allianz_policy_issuance_status_query.execute({
        customer_id: this.customer_id,
      });
      this.is_policy_on_issue_process = true;
      // eslint-disable-next-line max-len
      this.is_first_interaction_with_dashboard = (
        this.has_allianz_account && !allianz_policy_status.first_interaction_with_dashboard
      );
      this.is_status_account_manual = allianz_policy_status.account_status_validated === 'MANUAL_REVIEW';
    } catch (error) {
      if (error.message !== this.error_allianz_policy_status_not_exists) {
        this.message_notifier.showErrorNotification('Ha ocurrido un error, inténtelo nuevamente');
      }
    }
  }

  loadAllianzData = async () => {
    try {
      const allianz_account = await this.get_allianz_account_query.execute({
        customer_id: this.customer_id,
      });
      this.first_contribution_was_applied = allianz_account.was_issued;
      this.allianz_policy_number = allianz_account.policy_number_issued;
      if (allianz_account.was_issued) {
        this.has_allianz_account = true;
        await this.loadAllianzBalance();
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification('Ha ocurrido un error, inténtelo nuevamente');
      }
    } finally {
      await this.validateIfPolicyIsOnProcess();
    }
  }

  goToWealthDashboard = () => {
    if (this.is_first_interaction_with_dashboard) {
      this.show_status_allianz_modal = true;
    }
    if (!this.is_first_interaction_with_dashboard) {
      this.router.navigate('sowos-wealth');
    }
  }

  showInfo = ($event: Event) => {
    $event.stopPropagation();
    this.show_info = true;
  }

  findAvailabilityToHire = async () => {
    let available_to_hire = false;
    const investment_products = await this.get_investment_products.execute();
    const investment_product = investment_products.find(
      (product) => product.name === 'sowos_wealth',
    );
    if (investment_product) {
      const availability = await this.find_availability_to_hire_product.execute(
        investment_product.id,
      );
      available_to_hire = availability.available_to_hire;
    }
    return available_to_hire;
  }

  start = async () => {
    const available = await this.findAvailabilityToHire();
    if (!available) {
      this.product_unavailable_to_hire = 'SOWOS Wealth';
    } else {
      await this.obtainInvestmentSuggestion();
      this.display_contract_savings = true;
      this.show_contract_savings_modal = true;
    }
  }

  showStatus = () => {
    this.show_status_allianz_modal = true;
  }

  translate = (value: string, count?: number, values?: Record<string, string | number>) => (values ? i18n.tc(`${this.i18n_namespace}.${value}`, count, values) as string : i18n.t(`${this.i18n_namespace}.${value}`) as string);

  setBackgroundImage = () => {
    if (this.has_allianz_account) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
      const image = require('@/assets/images/investment-dashboard/background_blue.svg');
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
      const image_mobile = require('@/assets/images/investment-dashboard/background_blue.png');
      // eslint-disable-next-line no-restricted-globals
      this.bg_image.backgroundImage = (screen.width < 480) ? `url(${image_mobile})` : `url(${image})`;
      this.bg_image.backgroundSize = 'cover';
    }

    if (!this.has_allianz_account) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-unresolved
      const image = require('@/assets/images/investment-dashboard/join_wealth.svg');
      this.bg_image.backgroundImage = `url(${image})`;
      this.bg_image.backgroundSize = 'contain';
    }
  }

  async initialize() {
    this.is_loading = true;
    await this.loadAllianzData();
    await this.setBackgroundImage();
    this.is_loading = false;
  }

  getRecommendedProduct = async (investor_profile_id: string) => {
    try {
      const { investment_product } = await this.get_recommended_product_query
        .execute(investor_profile_id);
      if (investment_product) {
        this.show_recommended = (investment_product.name === 'sowos_wealth');
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investor_profile'));
    }
  }

  obtainInvestmentSuggestion = async () => {
    try {
      const { id, must_be_shown } = await this.get_investment_suggestion_query.execute();
      if (must_be_shown) {
        this.investment_suggestion_dto.investment_suggestion_analytic_id = id;
        await this.createInvestmentSuggestionAction();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investment_suggestion'));
    }
  }

  createInvestmentSuggestionAction = async () => {
    try {
      await this.create_investment_suggestion_command.execute(this.investment_suggestion_dto);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_investment_suggestion_action'));
    }
  }
}
