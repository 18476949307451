





















































import { Component, PropSync, Vue } from 'vue-property-decorator';
import SowosWealthInfoViewModel from '@/vue-app/view-models/components/my-investment-dashboard-v2/sowos-wealth-info-view-model';

@Component({ name: 'SowosWealthInfo' })
export default class SowosWealthInfo extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  sowos_wealth_info_model = Vue.observable(new SowosWealthInfoViewModel());

  closeModal() {
    this.synced_is_open = false;
  }
}
