import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Application
import {
  PatchAllianzPolicyIssuanceStatusCommand,
} from '@/modules/my-investment/allianz/allianz-policy-issuance-status/application/commands';

import {
  GetAllianzPolicyIssuanceStatusQuery,
} from '@/modules/my-investment/allianz/allianz-policy-issuance-status/application/queries';

// Domain
import {
  PatchAllianzPolicyIssuanceStatusEntity,
} from '@/modules/my-investment/allianz/allianz-policy-issuance-status/domain/entities/patch-allianz-policy-issuance-status-entity';

import Inject from '@/modules/shared/domain/di/inject';

import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Router } from '@/modules/shared/domain/router';

export default class PolicyStatusAllianzViewModel {
  @Inject(TYPES.PATCH_ALLIANZ_POLICY_ISSUANCE_STATUS_COMMAND)
  private readonly patch_allianz_policy_issuance_status!: PatchAllianzPolicyIssuanceStatusCommand;

  @Inject(TYPES.GET_ALLIANZ_POLICY_ISSUANCE_STATUS_QUERY)
  private readonly get_allianz_policy_issuance_status_query!: GetAllianzPolicyIssuanceStatusQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly i18n_namespace = 'components.home-dashboard.policy_status_allianz';

  private readonly error_message = i18n.t('components.allianz-dashboard.errors.try_again.error_message');

  policy_issuance_status_id = '';

  getAllianzPolicyIssuanceStatus = async () => {
    try {
      const policy_issuance_status = await this.get_allianz_policy_issuance_status_query.execute({
        customer_id: this.customer_id,
      });
      this.policy_issuance_status_id = policy_issuance_status.id;
    } catch (error) {
      this.message_notifier.showErrorNotification(`${this.error_message}`);
    }
  }

  updateFirstInteractionWithDashboard = async () => {
    try {
      await this.getAllianzPolicyIssuanceStatus();
      const patch_status_allianz: PatchAllianzPolicyIssuanceStatusEntity = {
        id: this.policy_issuance_status_id,
        first_interaction_with_dashboard: true,
      };
      await this.patch_allianz_policy_issuance_status.execute(patch_status_allianz);
      this.router.navigate('sowos-wealth');
    } catch (error) {
      this.message_notifier.showErrorNotification(`${this.error_message}`);
    }
  }
}
