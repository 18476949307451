






































































































































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';

import PolicyStatusAllianzViewModel
  from '@/vue-app/view-models/allianz-dashboard/policy-status-allianz/policy-status-allianz-view-model';

@Component({ name: 'PolicyStatusAllianzModal' })
export default class PolicyStatusAllianzModal extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  @PropSync('stepNumber', { type: Number, required: true })
  step!: number;

  @PropSync('policyNumber', { type: String, required: true })
  policy_number!: string;

  policy_issuance_status_view_model = Vue.observable(new PolicyStatusAllianzViewModel());

  async goToDashboard() {
    await this.policy_issuance_status_view_model.updateFirstInteractionWithDashboard();
  }
}
